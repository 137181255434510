<template>
  <div>
    <div id="modals-container"></div>
    <v-row class="pt-3 pr-3">
      <v-spacer></v-spacer>
      <!-- <Search-box
        :emitDestination="searchEmitDestination"
        searchPlaceholder="Pretraži klijente"
      ></Search-box> -->
    </v-row>
    <v-container>
      <v-row style="margin-top: 0px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-expansion-panels focusable>
            <v-expansion-panel
              v-for="(item, i) in notifications"
              :key="i"
              hover
              tabindex="0"
            >
              <v-expansion-panel-header
                height="44"
                :class="[
                  item.readed !== true ? 'status-failed-exp' : 'status-ok-exp',
                ]"
              >
                <v-row>
                  <v-col
                    cols="8"
                    sm="5"
                    md="6"
                    lg="3"
                    :class="[densityPadding]"
                  >
                    <div class="pl-1 caption grey--text">Naslov</div>
                    <div class="pl-1 text-capitalize">{{ item.subject }}</div>
                  </v-col>
                  <v-col
                    v-if="!$vuetify.breakpoint.mdAndDown"
                    style="min-width: 100px; max-width: 100%"
                    :class="[densityPadding, 'flex-grow-1']"
                  >
                    <div class="pl-1 caption grey--text">Status</div>
                    <div class="pl-1 text-capitalize">
                      {{ showStatus(item.readed) }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="!$vuetify.breakpoint.mdAndDown"
                    style="min-width: 100px; max-width: 100%"
                    :class="[densityPadding, 'flex-grow-1']"
                  >
                    <div class="pl-1 caption grey--text">Tip</div>
                    <div class="pl-1 text-capitalize-first">
                      {{ showType(item.type) }}
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="mt-2">
                  <v-col cols="8"
                    ><p class="font-weight-bold">Obavijest</p>
                    <div v-html="item.text"></div>
                  </v-col>
                  <v-spacer></v-spacer>
                  <!-- <v-label>Označi kao pročitano</v-label>
                  <v-checkbox
                    @change="changeNotifStatus(item)"
                    v-model="item.read"
                  ></v-checkbox> -->
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
// import state from '@/state'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'

export default {
  components: { },
  mixins: [flow, applicationSettings],
  inject: ['showLoader', 'hideLoader'],
  data: function () {
    return {
      notifications: [],
      currentNotifications: [],
      lastVisible: {},
      listeners: []
    }
  },
  watch: {
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  mounted () {
    const query = df.collection(`user_notifications/${auth.currentUser.uid}/notifications`)
      .orderBy('created', 'asc')

    const listener = query
      .onSnapshot((doc) => {
        if (doc.docs.length > 0) {
          this.notifications = []
          doc.docs.forEach((rec, key) => {
            this.notifications.push(rec.data())
          })
        } else {
          this.showNotif = false
          this.importantNotif = undefined
        }
      }
      )
    this.listeners.push(listener)
  },
  methods: {
    showType (type) {
      switch (type) {
        case 'CONFIRMATION':
          return 'Važna obavijest - potrebna potvrda'
        case 'IMPORTANT':
          return 'Važna obavijest'
        case 'NOTIFICATION':
          return 'Obavijest'
        case 'WARNING':
          return 'Obavijest'
      }
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    showStatus (read) {
      switch (read) {
        case true:
          return 'Pročitano'
        case false:
          return 'Nepročitano'
      }
    },
    changeNotifStatus (notif) {
      df.doc(`user_notifications/${auth.currentUser.uid}/notifications/${notif.id}`).update({ readed: notif.read })
    }

  }
}
</script>
<style scoped>
.container >>> .v-expansion-panel-header {
  padding: 0 !important;
}

.myTest >>> .vm--modal {
  top: 100px !important;
}
</style>
